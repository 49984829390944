import React, { PureComponent, Fragment } from "react";
import kebabCase from "lodash.kebabcase";
import classNames from "classnames";

import ScrolledContainer from "../ScrolledContainer";
import { Grid, Column, Link, Image } from "../../components";
import { withBreakpoint, cloudfrontImage } from "../../helpers";

import "./About.scss";
import { AboutMeta } from "../../.config/meta";
import { smbData } from "../../.config/environment";

class About extends PureComponent {
  state = {
    size: "mobile",
  };

  render() {
    const { about: aboutData, jobs: jobsData } = smbData();
    const isMobile = this.state.size === "mobile";

    return (
      <ScrolledContainer>
        <AboutMeta />
        <div className="about">
          <Grid gutter={false}>
            <Column offset={1} size={17}>
              <Fragment>
                <h1 className="type--b1">about</h1>
                <p
                  className="type--a2"
                  style={{ marginTop: 5 }}
                  dangerouslySetInnerHTML={{ __html: aboutData.aboutFirst }}
                ></p>
              </Fragment>
            </Column>
          </Grid>
          <Grid gutter={false} className="about__second">
            <Column offset={isMobile ? 11 : 9} size={isMobile ? 7 : 9}>
              <p
                className="type--a32"
                dangerouslySetInnerHTML={{ __html: aboutData.aboutSecond }}
              ></p>
            </Column>
          </Grid>
          <Grid gutter={false} className="about__third">
            <Column offset={1} size={isMobile ? 14 : 9}>
              <Fragment>
                <h2 className="type--b1" style={{ paddingLeft: "7%" }}>
                  our approach
                </h2>
                <p
                  className="type--a32"
                  style={{ marginTop: 20 }}
                  dangerouslySetInnerHTML={{ __html: aboutData.approach }}
                ></p>
              </Fragment>
            </Column>
          </Grid>
          {!isMobile && (
            <Grid gutter={false} flush={true} style={{ marginBottom: -200 }}>
              <Column offset={14} size={4}>
                <img
                  className="grid__img about__img"
                  src={cloudfrontImage("about-primary.jpg")}
                  alt="balloon"
                />
              </Column>
            </Grid>
          )}
          <Grid gutter={false} style={{ marginTop: isMobile ? 20 : 0 }}>
            <Column offset={isMobile ? 9 : 1} size={9}>
              <h2 className="type--a2 type--blue-1">services</h2>
            </Column>
          </Grid>
          <Grid gutter={false} className="about__strategy">
            <Column offset={1} size={10}>
              <Fragment>
                <h3 className="type--a32 about__strategy__title">
                  Brand Strategy
                </h3>
                <ul className="hash-list type--b1">
                  {aboutData.strategy.map((strategy) => (
                    <li
                      className="hash-list__item"
                      key={strategy}
                      dangerouslySetInnerHTML={{ __html: strategy }}
                    ></li>
                  ))}
                </ul>
              </Fragment>
            </Column>
          </Grid>
          <Grid gutter={false} className="about__design">
            <Column offset={10} size={8}>
              <Fragment>
                <h3 className="type--a32 about__strategy__title">
                  Brand Design
                </h3>
                <ul className="hash-list type--b1">
                  {aboutData.brandDesign.map((service) => (
                    <li
                      className="hash-list__item"
                      key={service}
                      dangerouslySetInnerHTML={{ __html: service }}
                    ></li>
                  ))}
                </ul>
              </Fragment>
            </Column>
          </Grid>
          <Grid gutter={false} className="about__campaign">
            <Column offset={isMobile ? 4 : 6} size={isMobile ? 11 : 14}>
              <Fragment>
                <h3 className="type--a32 about__strategy__title">Campaign</h3>
                <ul className="hash-list type--b1">
                  {aboutData.campaign.map((camp) => (
                    <li
                      className="hash-list__item"
                      key={camp}
                      dangerouslySetInnerHTML={{ __html: camp }}
                    ></li>
                  ))}
                </ul>
              </Fragment>
            </Column>
          </Grid>

          {isMobile && (
            <Grid gutter={false} flush={true} style={{ marginTop: 20 }}>
              <Column offset={11} size={7}>
                <img
                  className="grid__img about__img"
                  src={cloudfrontImage("about-primary.jpg")}
                  alt=""
                />
              </Column>
            </Grid>
          )}

          {!isMobile && (
            <Grid gutter={false} style={{ marginTop: 150 }}>
              <Column offset={4} size={5}>
                <img
                  src={cloudfrontImage("about-services.jpg")}
                  alt="speaker on the floor"
                  style={{ width: "100%" }}
                />
              </Column>
              <Column offset={12} size={4}>
                <p
                  className="type--b3"
                  style={{ marginTop: 70 }}
                  dangerouslySetInnerHTML={{
                    __html: aboutData.servicesDescription,
                  }}
                ></p>
              </Column>
            </Grid>
          )}

          <Grid style={{ marginTop: isMobile ? 30 : 100 }}>
            <Column offset={1} size={17}>
              <h2 className="type--a2 type--blue-1">our friends</h2>
            </Column>
          </Grid>

          {isMobile && (
            <Grid gutter={false} style={{ marginTop: 20 }}>
              <Column offset={5} size={12}>
                <p
                  className="type--b1"
                  dangerouslySetInnerHTML={{
                    __html: aboutData.servicesDescription,
                  }}
                ></p>
              </Column>
            </Grid>
          )}

          <ul className="logo-list">
            {aboutData.clientLogos.map((logo) => (
              <li key={logo} className="logo-list__item">
                <Image assetId={logo} />
              </li>
            ))}
          </ul>

          <Grid gutter={false} style={{ marginTop: isMobile ? 40 : 90 }}>
            <Column offset={isMobile ? 0 : 9} size={isMobile ? 11 : 9}>
              <img
                className="grid__img about__img"
                src={cloudfrontImage("about-collaborate.jpg")}
                alt=""
              />
            </Column>
          </Grid>
          <Grid gutter={false} style={{ marginTop: 20 }}>
            <Column offset={isMobile ? 5 : 2} size={isMobile ? 13 : 8}>
              <div className="about__cta">
                <p className="type--a2 type--blue-1 about__cta__copy">
                  let's do this!
                </p>
                <a
                  className="link link--black type--b1 type--block about__email"
                  href="#"
                >
                  hello@supermorebetter.com
                </a>
              </div>
            </Column>
          </Grid>

          {jobsData && (
            <Fragment>
              <Grid style={{ marginTop: isMobile ? 30 : 0 }}>
                <Column offset={1} size={17}>
                  <h2 className="type--a2 type--blue-1">open jobs</h2>
                </Column>
              </Grid>
              <Grid style={{ marginTop: 20 }}>
                <Column
                  offset={1}
                  size={isMobile ? 10 : 7}
                  className={classNames({
                    "about--no-positions": !jobsData.length,
                  })}
                >
                  {jobsData.length ? (
                    <ul className="career-list">
                      {jobsData.map(({ position }) => (
                        <li key={position} className="career-list__item">
                          <Link
                            className="type--a32 type--lowercase type--black type--underline"
                            to={`/careers/${kebabCase(position)}`}
                          >
                            {position}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className="type--b3">
                      we do not have any open positions at this time
                    </p>
                  )}
                </Column>
                <Column
                  offset={isMobile ? 11 : 8}
                  size={isMobile ? 7 : 4}
                  style={{ marginTop: isMobile ? 0 : -27 }}
                >
                  <img
                    src={cloudfrontImage("about-jobs.jpg")}
                    style={{ width: "100%" }}
                    alt="never comply"
                  />
                </Column>
                {!isMobile && (
                  <Column offset={13} size={4} style={{ marginTop: "9%" }}>
                    <p
                      className="type--b3"
                      dangerouslySetInnerHTML={{
                        __html: aboutData.jobsDescription,
                      }}
                    ></p>
                  </Column>
                )}
              </Grid>
              {isMobile && (
                <Grid
                  gutter={false}
                  style={{ marginTop: -100, marginBottom: 20 }}
                >
                  <Column offset={1} size={9}>
                    <p
                      className="type--b1"
                      dangerouslySetInnerHTML={{
                        __html: aboutData.jobsDescription,
                      }}
                    ></p>
                  </Column>
                </Grid>
              )}
            </Fragment>
          )}
        </div>
      </ScrolledContainer>
    );
  }

  //  ==========================================================================
  //  Breakpoint functinoality
  //  ==========================================================================
  _onBreakpointChange({ newBreakpoint, previousBreakpoint }) {
    switch (newBreakpoint) {
      case "sm":
        this.setState(() => ({ size: null }));
        this.setState(() => ({ size: "mobile" }));
        return;
      case "md":
      case "lg":
      case "xl":
        this.setState(() => ({ size: null }));
        this.setState(() => ({ size: "tablet" }));
        return;
      default:
        console.log("case not set up for:", typeof newBreakpoint);
        return;
    }
  }
}

export default withBreakpoint(About, { leading: true });
