import React, { useState, useRef, useEffect } from 'react'
import classNames from 'classnames'

import { ImgVideo } from '..'
import { getAsset } from '../../helpers'

import './styles.scss'

const HoverImg = ({ download = false, useAudio, assetId, children }) => {
  const [active, setActive] = useState(false)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const [dt, setDt] = useState('')
  const [img, setImg] = useState()
  const link = useRef()
  const canvas = useRef()

  useEffect(() => () => {
    if (img) img.onload = undefined
  })

  if (!assetId) return null

  const { url, title } = getAsset(assetId)
  const isVideo = url.match(/\.mp4/)
  const isGif = url.match(/\.gif/)
  const isImg = !isVideo && !isGif
  const hideBack = !isImg && React.Children.count(children) === 0

  const onImgLoad = ({ target }) => {
    if (isGif) return
    const { width, height, src } = target
    setDimensions({ width, height })

    const ctx = canvas.current.getContext('2d')
    const newImg = new Image()
    newImg.setAttribute('crossOrigin', 'anonymous')
    newImg.onload = () => {
      ctx.drawImage(newImg, 0, 0, width, height)
      const dt = canvas.current
        .toDataURL('image/png')
        .replace(/^data:image\/[^;]*/, 'data:application/octet-stream')
        .replace(
          /^data:application\/octet-stream/,
          'data:application/octet-stream;headers=Content-Disposition%3A%20attachment%3B%20filename=Canvas.png'
        )
      setDt(dt)
    }
    newImg.src = src
    setImg(newImg)
  }

  const toggleBack = () => setActive(!active)

  // TODO: handle touches on children to not hide
  const reveal = () => {
    toggleBack()

    setTimeout(() => {
      window.addEventListener('touchstart', hide)
    }, 10)
  }

  const hide = () => {
    toggleBack()
    window.removeEventListener('touchstart', hide)
  }

  return (
    <div className={classNames('hover-img', { 'hover-img--video': isVideo })} onTouchStart={reveal}>
      <ImgVideo assetId={assetId} onLoad={onImgLoad} useAudio={useAudio} />
      <canvas
        style={{ display: 'none' }}
        width={dimensions.width}
        height={dimensions.height}
        ref={canvas}
      ></canvas>
      {!hideBack && (
        <div className="hover-img__back">
          <div className="hover-img__back__content">
            {children}
            {isImg && download && (
              <a
                className="type--b2 type--white type--underline type--block"
                href={dt}
                ref={link}
                download={`${title}.png`}
              >
                download
              </a>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default HoverImg
