import { createStore, applyMiddleware } from "redux";
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import rootReducer from "../reducers";

// if (process.env.NODE_ENV !== 'production') {
//   const logger = require('redux-logger').default
//   middleWare.push(logger)
// }

export const configureStore = (history) => {
  const middleWare = [thunk, routerMiddleware(history)];
  return (initialState = {}) => {
    const store = createStore(
      rootReducer(history),
      initialState,
      applyMiddleware(...middleWare)
    );

    return store;
  };
};
