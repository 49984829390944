import { smbData } from "../.config/environment";

// arr is an array of pairs [offset, size], where offset is the starting
// index, and the size is the amount to consume from the offset.
export const fillGaps = (arr, max = 18) => {
  const sorted = arr.sort((a, b) => a[0] - b[0]);

  return sorted.reduce((acc, cur, i) => {
    const [offset, size] = cur;
    const next = sorted[i + 1];
    const nextOffset = offset + size;

    // if the first item doesnt have a 0 offset, pad start
    if (i === 0 && offset > 0) acc.push([0, offset]);
    acc.push(cur);

    // if the nextOffset is equal to the next items offset or
    // if you're at the end
    if ((next && nextOffset === next[0]) || nextOffset >= max) {
      return acc;
    }

    // if there isnt a next one, you're at the end and the
    // ending needs padding, else take the next start - nextOffset
    const nextSize = !next ? max - nextOffset : next[0] - nextOffset;
    return acc.concat([[nextOffset, nextSize]]);
  }, []);
};

export const inGroupsOf = (arr, size) =>
  arr.reduce(
    (a, c, i) => (i % size === 0 ? [...a, arr.slice(i, size + i)] : a),
    []
  );

export const hasPresence = (item) => {
  switch (typeof item) {
    case "number":
      return true;
    case "string":
      return item.length > 0;
    case "object":
      if (Array.isArray(item)) {
        return item.length > 0;
      } else if (item === null) {
        return false;
      }
      return Object.keys(item).length > 0;
    case "undefined":
      return false;
    default:
      return !!item;
  }
};

export const combineModifiers = (base, mods = []) =>
  mods.reduce((a, c) => `${a} ${base}--${c}`, "").trim();

export const repeat = (i, arr = []) =>
  i <= 0 ? arr.reverse() : repeat(i - 1, arr.concat(i - 1));

export const shuffle = (array) => {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

export const limitToData = (full, data) => {
  let rowI = 0;
  let itemI = 0;
  return data.reduce(
    (acc, cur, i) => {
      if (full[rowI][itemI]) {
        itemI += 1;
        return [...acc.slice(0, rowI), [...acc[rowI], cur]];
      } else {
        itemI = 1;
        rowI += 1;
        return [...acc, [cur]];
      }
    },
    [[]]
  );
};

export const observerWrapper =
  (callback) =>
  (entries, ...rest) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) callback(entries, ...rest);
    });
  };

export const getWindowHeight = () =>
  window.innerHeight || document.documentElement.clientHeight;

export const triggerGtagPageview = () => {
  if (process.env.NODE_ENV === "development") return;

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag("set", "anonymizeIp", true);
  gtag("js", new Date());
  gtag("config", "UA-145018119-1");
};

export const getAsset = (id) => smbData().assets[id] || { url: "" };
export const getProject = (slug) =>
  smbData().projects.find((pro) => pro.slug === slug);

const cloudfrontAsset = (key) =>
  process.env.NODE_ENV === "development"
    ? `https://staging.supermorebetter.com/${key}`
    : `/${key}`;

export const cloudfrontImage = (filename) =>
  cloudfrontAsset(`assets/${filename}`);
export const cloudfrontStream = (filename) =>
  cloudfrontAsset(`streams/${filename}-hls-video.m3u8`);
