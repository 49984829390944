import React from "react";
import { Helmet } from "react-helmet";
import { paths } from "../helpers";

const DOMAIN = "https://www.supermorebetter.com";
const TITLE =
  "supermorebetter - your design & strategy studio for positive impact.";
const DESCRIPTION =
  "Build and Grow a powerful brand with us. We're a creative studio specializing in brand design, strategy, and art direction, helping businesses with world-changing missions achieve positive impact. Let's turn your vision into reality. Let's make something great together.";
const IMAGE = "/supermorebetter_designandstrategy_previewIMG.png";

const Meta = ({
  title = TITLE,
  description = DESCRIPTION,
  url = DOMAIN,
  image = IMAGE,
}) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:url" content={url} />
    <meta property="og:image" content={image} />
  </Helmet>
);

export const HomeMeta = () => <Meta />;
export const StudiesMeta = () => <Meta />;

export const AboutMeta = () => (
  <Meta title="about - supermorebetter" url={`${DOMAIN}${paths.ABOUT}`} />
);
export const CareerMeta = ({ title, path }) => (
  <Meta title={title} url={`${DOMAIN}${path}`} />
);
export const ProjectMeta = ({ title, path, image, description }) => (
  <Meta
    title={title}
    url={`${DOMAIN}${path}`}
    image={image}
    description={description}
  />
);
