import React from 'react'
import Hls from 'hls.js'

import { getAsset, cloudfrontStream } from '../helpers/utils'

class Video extends React.PureComponent {
  constructor(props) {
    super(props)
    this.getAttributes()
    this.hls = null
    this.observer = null
  }

  getAttributes() {
    const { title } = getAsset(this.props.assetId)
    const { url: posterUrl } = getAsset(this.props.posterId) || {}

    this.src = cloudfrontStream(`${this.props.assetId}/${title.replace('/', '-').replace(/\s/g, '_')}`)

    this.title = title
    this.posterUrl = posterUrl
  }

  initPlayer() {
    const { autoPlay, innerRef, hlsConfig = {} } = this.props

    let hls = new Hls(hlsConfig)

    hls.loadSource(this.src)
    hls.attachMedia(innerRef.current)
    hls.on(Hls.Events.MANIFEST_PARSED, () => {
      if (autoPlay) {
        this.observer = new IntersectionObserver(this.playHandler, { threshold: 0.5 })
        this.observer.observe(innerRef.current)
      }
    })

    this.hls = hls
  }

  componentDidMount() {
    this.initPlayer()
  }

  componentDidUpdate({assetId}) {
    if (assetId !== this.props.assetId) {
      this.getAttributes()
      this.initPlayer()
    }
  }

  componentWillUnmount() {
    if (this.hls && this.hls.destroy) {
      this.hls.destroy()
    }

    if (this.observer) {
      this.observer.disconnect()
    }
  }

  playHandler = (entries) => {
    const { intersectionRatio } = entries[0]

    if (intersectionRatio < 0.5) {
      this.props.innerRef.current.pause()
    } else {
      this.props.innerRef.current.play()
    }
  }

  render() {
    const { innerRef, title, assetId, posterId, autoPlay, ...rest } = this.props
    if (this.props.log) console.log('video', this.props)

    return (
      <video
        src={this.src}
        alt={this.title}
        poster={this.posterUrl}
        ref={innerRef}
        {...rest}
        playsInline
      ></video>
    )
  }
}

export default React.forwardRef((props, ref) => <Video innerRef={ref} {...props} />)
