import React, { PureComponent, createRef, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link as RouterLink } from 'react-router-dom'
import { TweenLite, TimelineLite, TimelineMax, Power1 } from 'gsap'

import { Grid, GridWithRef, Column, NavEl, Link, Logo } from '..'
import { withBreakpoint, paths, NAV_STATES } from '../../helpers'
import { setNavState } from '../../actions'
import { smbVert } from '../../assets/images'

import './NavBar.scss'

const { PARTIAL, PARTIAL_TO_NORMAL, NORMAL, SHOW, REDUCED } = NAV_STATES
const ease = Power1.easeInOut

class NavBar extends PureComponent {
  _firstRowRef = createRef()
  _instagramRowRef = createRef()
  _locationRowRef = createRef()
  _logoRef = createRef()
  _nameRef = createRef()
  _sloganRef = createRef()
  _transform = 0
  _isMobile = false

  componentDidMount() {
    this._reducedTimeline = new TimelineLite({ paused: true })
    const transformDuration = 0.4
    const opacityDuration = 0.25

    this._reducedTimeline
      .fromTo(this._nameRef.current, transformDuration, { y: 0 }, { y: -200, ease })
      .fromTo(this._sloganRef.current, transformDuration, { y: 0 }, { y: -200, ease }, 0.05)
      .fromTo(this._instagramRowRef.current, transformDuration, { y: 0 }, { y: -200, ease }, 0.15)
      .fromTo(this._locationRowRef.current, transformDuration, { y: 0 }, { y: -200, ease }, 0.25)
      .fromTo(this._nameRef.current, opacityDuration, { opacity: 1 }, { opacity: 0, ease }, 0.05)
      .fromTo(this._sloganRef.current, opacityDuration, { opacity: 1 }, { opacity: 0, ease }, 0.1)
      .fromTo(
        this._instagramRowRef.current,
        opacityDuration,
        { opacity: 1 },
        { opacity: 0, ease },
        0.2
      )
      .fromTo(
        this._locationRowRef.current,
        opacityDuration,
        { opacity: 1 },
        { opacity: 0, ease },
        0.3
      )

    if (this.props.navState === PARTIAL) {
      setTimeout(this._calcTransforms, 500)
    }
    this._goToNavState(this.props.navState)
  }

  componentDidUpdate({ navState }) {
    if (navState !== PARTIAL && this.props.navState === PARTIAL) this._calcTransforms()

    if (navState === PARTIAL && this.props.navState === PARTIAL_TO_NORMAL) {
      this._transitionFromPartialToNormal()
      return
    }

    if (navState !== PARTIAL_TO_NORMAL) {
      this._goToNavState(this.props.navState)
    }
  }

  render() {
    const navBarState =
      this.props.navState === PARTIAL_TO_NORMAL ? 'partial' : this.props.navState.toLowerCase()

    return (
      <div
        className={`nav-bar pointer--none nav-bar--${navBarState}`}
        style={{ transform: this._calcNavTransorm() }}
      >
        <GridWithRef align="flex-end" className="nav-bar__first-row" ref={this._firstRowRef}>
          <Column offset={0} size={2} style={{ lineHeight: 0 }}>
            <RouterLink
              to={paths.PROJECTS}
              className="type--inline-block pointer--auto nav-bar__logo js-nav-bar-logo"
              ref={this._logoRef}
            >
              <Logo />
            </RouterLink>
          </Column>
          <Column offset={5} size={8}>
            <div className="nav-bar__name" ref={this._nameRef}>
              <img src={smbVert} alt="super more better" />
            </div>
          </Column>
          <Column offset={13} size={5}>
            <p
              className={`vertical-text type--red-1 type--b1 nav-bar__slogan`}
              dangerouslySetInnerHTML={{
                __html: 'Driven by insights,<br/>designed with instinct.<br/>&trade;&copy&reg;',
              }}
              ref={this._sloganRef}
            ></p>
          </Column>
        </GridWithRef>
        <GridWithRef
          className={`is-visible-lg--flex nav-bar__instagram`}
          ref={this._instagramRowRef}
        >
          <Column offset={0} size={2}>
            <p className="type--b1">instagram</p>
          </Column>
          <Column offset={11} size={7}>
            <Link
              className="type--black type--b1 type--underline pointer--auto"
              href="#instagram"
              target="_blank"
            >
              @supermorebetter
            </Link>
          </Column>
        </GridWithRef>
        <GridWithRef className="is-visible-lg--flex nav-bar__details" ref={this._locationRowRef}>
          <Column offset={0} size={13}>
            <p className="type--b1">a global design studio</p>
          </Column>
          <Column offset={13} size={5}>
            <Fragment>
              <Link
                href="mailto:hello@supermorebetter.com"
                className="type--block type--right type--b1 type--underline pointer--auto"
              >
                hello@supermorebetter.com
              </Link>
              <Link
                href="tel:15032086052"
                className="type--block type--right type--b1 type--underline pointer--auto"
              >
                +1.503.208.6052
              </Link>
            </Fragment>
          </Column>
        </GridWithRef>
        <Grid className="nav-bar__nav">
          <Column offset={13} size={5}>
            <NavEl />
          </Column>
        </Grid>
      </div>
    )
  }

  _calcNavTransorm = () =>
    this._nameRef.current && [SHOW, REDUCED].includes(this.props.navState)
      ? `translateY(${-this._nameRef.current.offsetHeight + (this._isMobile ? 25 : 45)}px)`
      : undefined

  _calcTransforms = () => (this._transform = this._nameRef.current.offsetTop)

  _transitionFromPartialToNormal = () => {
    const tLine = new TimelineMax({ onComplete: this._transitionComplete })
    const singleDuration = 0.75
    const overlap = `-=${singleDuration / 2}`

    tLine
      .to(this._logoRef.current, singleDuration, {
        y: -this._transform + 'px',
        ease,
      })
      .to(this._nameRef.current, singleDuration, { y: -this._transform, ease }, overlap)
      .to(
        this._sloganRef.current,
        singleDuration,
        {
          y: -this._transform,
          ease,
        },
        overlap
      )
      .to(this._firstRowRef.current, 0, { height: 'auto' })
      .set(this._logoRef.current, { y: 0 })
      .set(this._nameRef.current, { y: 0 })
      .set(this._sloganRef.current, { y: 0 })
      .addCallback(() => window.scrollTo(0, 0))
      .fromTo(
        this._instagramRowRef.current,
        singleDuration,
        { y: 120, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          ease,
        }
      )
      .fromTo(
        this._locationRowRef.current,
        singleDuration,
        { y: 120, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          ease,
        },
        overlap
      )
  }

  _transitionToPartial = () => {
    TweenLite.set(this._nameRef.current, { y: 0, opacity: 1 })
    TweenLite.set(this._sloganRef.current, { y: 0, opacity: 1 })
    TweenLite.set(this._instagramRowRef.current, { opacity: 0 })
    TweenLite.set(this._locationRowRef.current, { opacity: 0 })
  }

  _goToNavState = (state) => {
    switch (state) {
      case NORMAL:
        this._reducedTimeline.reverse()
        return
      case PARTIAL:
        this._transitionToPartial()
        return
      case REDUCED:
      case SHOW:
        this._reducedTimeline.play(0)
        return
      default:
        return
    }
  }

  _transitionComplete = () => {
    this.props.setNavState(NAV_STATES.NORMAL)
  }

  //  ==========================================================================
  //  Breakpoint functinoality
  //  ==========================================================================
  _onBreakpointChange({ newBreakpoint, previousBreakpoint }) {
    switch (newBreakpoint) {
      case 'sm':
        this._isMobile = true
        return
      case 'md':
      case 'lg':
      case 'xl':
        this._isMobile = false
        return
      default:
        console.log('case not set up for: ', typeof newBreakpoint)
        return
    }
  }
}

const mapStateToProps = ({ nav }) => ({ navState: nav.state })

export default withRouter(
  connect(mapStateToProps, { setNavState })(withBreakpoint(NavBar, { leading: true }))
)
