import React from "react";

import { Grid, Column, HoverImg } from "../../components";
import ScrolledContainer from "../ScrolledContainer";
import { shuffle, limitToData } from "../../helpers/utils";
import { StudiesMeta } from "../../.config/meta";

import "./styles.scss";
import { smbData } from "../../.config/environment";

let studyRowsCache = undefined;
let rowSet = []
const generateRandomStudyRows = (studiesData) => {
  if (studyRowsCache) return studyRowsCache;

  // prettier-ignore
  const randomRows = [
    [ [0, 5], [6, 5], [13, 5, 'flex-end'] ],
    [ [4, 8] ],
    [ [0, 4], [8, 6] ],
    [ [4, 4], [10, 6] ],
    [ [8, 5], [14, 4] ],
  ]

  for (let i = 0; i < Math.ceil(studiesData.studyImages.length / 10); i++) {
    rowSet.push(...shuffle(randomRows));
  }
  studyRowsCache = limitToData(rowSet, studiesData.studyImages);
  return studyRowsCache;
};

const studies = () => {
  const { studies: studiesData } = smbData();
  const studyRows = generateRandomStudyRows(studiesData);
  return (
    <ScrolledContainer>
      <StudiesMeta />
      <div className="studies">
        <h2 className="is-visually-hidden">Studies</h2>
        <Grid>
          <Column offset={0} size={15}>
            <p className="type--a2">{studiesData.description}</p>
          </Column>
        </Grid>
        <div className="studies-rows">
          <Rows data={studyRows} />
        </div>
      </div>
    </ScrolledContainer>
  );
};

const Rows = ({ data }) =>
  data.map((row, i) => (
    <Grid
      responsive={true}
      align="flex-start"
      flush={true}
      gutter={false}
      key={i}
    >
      {row.map((id, k) => (
        <Column
          offset={rowSet[i][k][0]}
          size={rowSet[i][k][1]}
          style={{ alignSelf: rowSet[i][k][2] }}
          key={id}
        >
          <div className="study">
            <HoverImg assetId={id} download={true} useAudio />
          </div>
        </Column>
      ))}
    </Grid>
  ));

export default studies;
