import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setSeenEntry } from "../../actions";
import get from "lodash.get";

import {
  Grid,
  Column,
  HoverImg,
  Link,
  ImgVideo,
  CopyGrid,
  PasswordProtected,
} from "../../components";
import {
  globalStyles,
  projectPath,
  hasPresence,
  withBreakpoint,
} from "../../helpers";
import { getAsset, getProject } from "../../helpers/utils";
import { ProjectMeta } from "../../.config/meta";

import "./styles.scss";

const { bodyHorizontalPadding } = globalStyles;

class Project extends PureComponent {
  state = {
    mobile: false,
    desktop: false,
    project: {},
  };

  _refs = {};

  componentDidMount() {
    this.props.setSeenEntry();
  }

  componentDidUpdate() {
    const { name } = this.props.match.params;

    // when project page first loads or changes
    if (this.state.project.slug !== name) {
      const nextProject = getProject(name);
      this.setState({ project: nextProject });
      document.body.classList.remove(this._colorClass);
      this._colorClass = nextProject.darkMode ? "dark-mode" : "light-mode";
      document.body.classList.add(this._colorClass);
    }
  }

  componentWillUnmount() {
    document.body.classList.remove(this._colorClass);
  }

  render() {
    const project = this.state.project.slug
      ? this.state.project
      : getProject(this.props.match.params.name);

    if (!project) {
      this.props.history.replace("/404");
      return null;
    }

    const {
      client,
      credits,
      description,
      footnote,
      title,
      nextProjects,
      password,
      featuredImage,
    } = project;
    const { mobile } = this.state;
    const imageRows = get(project, "imageRows", []);
    const metaDescription = imageRows.find(row => !row.gridItems)?.copy

    return (
      <PasswordProtected password={password}>
        <ProjectMeta
          title={`${title.replace(/(<br>)/g, " ")} - supermorebetter`}
          path={projectPath(this.props.match.params.name)}
          image={`${getAsset(featuredImage).url}?w=1200&q=75`}
          description={metaDescription}
        />

        <div className="project">
          <h1
            className="type--a51 project__title"
            style={{ padding: `0 ${bodyHorizontalPadding}px` }}
            dangerouslySetInnerHTML={{ __html: title }}
            ref={(node) => (this._refs.title = node)}
          ></h1>
          {hasPresence(description) && (
            <Grid>
              <Column offset={9} size={8}>
                <p
                  dangerouslySetInnerHTML={{ __html: description }}
                  className="type--b1"
                ></p>
              </Column>
            </Grid>
          )}
          {imageRows.map((fields, i) =>
            fields.gridItems ? (
              <Grid
                key={i}
                flush={fields.flush}
                gutter={false}
                align={fields.align}
                responsive
                style={{
                  marginTop: !mobile
                    ? this.calcMargin(fields.marginTop, i)
                    : undefined,
                }}
              >
                {fields.gridItems.map(
                  (
                    {
                      columns,
                      offset,
                      columnsOnMobile,
                      offsetOnMobile,
                      image,
                      text,
                      alignSelf,
                      useAudio,
                      videoPlaceholder,
                    },
                    k
                  ) => (
                    <Column
                      key={k}
                      offset={mobile ? offsetOnMobile : offset}
                      size={mobile ? columnsOnMobile : columns}
                      style={{ alignSelf: !mobile ? alignSelf : undefined }}
                    >
                      {image ? (
                        <ImgVideo
                          assetId={image}
                          useAudio={useAudio}
                          poster={videoPlaceholder}
                        />
                      ) : (
                        <p
                          className="type--b1"
                          dangerouslySetInnerHTML={{ __html: text }}
                        ></p>
                      )}
                    </Column>
                  )
                )}
              </Grid>
            ) : (
              <CopyGrid key={i} {...fields} />
            )
          )}

          <div className="credits">
            {this._clientSection(
              project,
              hasPresence(credits),
              hasPresence(client),
              hasPresence(footnote)
            )}
          </div>

          {hasPresence(nextProjects) && (
            <div className="suggestion">
              <h3
                className="suggestion__hd type--a2 type--blue-1"
                style={{ padding: `0 ${bodyHorizontalPadding}px` }}
              >
                Suggested <br className="is-hidden-lg" />
                Projects
              </h3>
              <Grid>
                {nextProjects.map(getProject).map((nextProject, i) => (
                  <Column
                    offset={mobile ? i * 8 + 2 : i * 7 + 3}
                    size={mobile ? 6 : 5}
                    key={i}
                  >
                    <Link
                      href={projectPath(nextProject.slug)}
                      attrs={{
                        onClick: this._onNextProjectLinkClick(
                          projectPath(nextProject.slug)
                        ),
                        target: "_self",
                      }}
                    >
                      <HoverImg assetId={nextProject.featuredImage}>
                        <p
                          className="type--b1 type--white type--center"
                          dangerouslySetInnerHTML={{
                            __html: nextProject.title,
                          }}
                        ></p>
                      </HoverImg>
                    </Link>
                  </Column>
                ))}
              </Grid>
            </div>
          )}
        </div>
      </PasswordProtected>
    );
  }

  calcMargin = (mt, i) => {
    const { mobile } = this.state;
    return i === 0 && mobile ? undefined : mt;
  };

  _onNextProjectLinkClick = (path) => (e) => {
    e.preventDefault();
    this.props.history.replace(path);
  };

  _clientSection = (project, hasCredits, hasClient, hasFootnote) => {
    if (!hasCredits && !hasClient && !hasFootnote) return null;
    const { credits, client, footnote } = project;

    return this.state.mobile ? (
      <div>
        <Grid>
          <Column offset={3} size={12}>
            <p className="type--b1">credits</p>
          </Column>
        </Grid>
        <Grid>
          <Column offset={3} size={12}>
            <Fragment>
              {hasCredits && (
                <p
                  dangerouslySetInnerHTML={{ __html: credits }}
                  className="type--b1"
                  style={{ marginTop: 20 }}
                ></p>
              )}
              {hasClient && (
                <p
                  dangerouslySetInnerHTML={{ __html: client }}
                  className="credits__client type--b1"
                  style={{ marginTop: 20 }}
                ></p>
              )}
              {hasFootnote && (
                <p
                  dangerouslySetInnerHTML={{ __html: footnote }}
                  className="type--b1"
                  style={{ marginTop: 20 }}
                ></p>
              )}
            </Fragment>
          </Column>
        </Grid>
      </div>
    ) : (
      <Fragment>
        <Grid>
          <Column offset={1} size={2}>
            <p className="type--b1">credits</p>
          </Column>
          <Column offset={3} size={4}>
            {hasCredits && (
              <p
                dangerouslySetInnerHTML={{ __html: credits }}
                className="type--blue-1 type--b1"
              ></p>
            )}
          </Column>
          <Column offset={9} size={6}>
            {hasClient && (
              <p
                dangerouslySetInnerHTML={{ __html: client }}
                className="credits__client type--b1"
              ></p>
            )}
          </Column>
        </Grid>
        {hasFootnote && (
          <Grid style={{ marginTop: 50 }}>
            <Column offset={4} size={5}>
              <p
                dangerouslySetInnerHTML={{ __html: footnote }}
                className="type--b1"
              ></p>
            </Column>
          </Grid>
        )}
      </Fragment>
    );
  };

  //  ==========================================================================
  //  Breakpoint functinoality
  //  ==========================================================================
  _onBreakpointChange({ newBreakpoint, previousBreakpoint }) {
    switch (newBreakpoint) {
      case "sm":
      case "md":
        this._setupSize("mobile");
        return;
      case "lg":
      case "xl":
        this._setupSize("desktop");
        return;
      default:
        console.log("case not set up for:", typeof newBreakpoint);
        return;
    }
  }

  _setupSize = (size) => {
    if (this.state[size]) return;
    const sizeToDisable = size === "mobile" ? "desktop" : "mobile";

    this._disableSize(sizeToDisable, () => this.setState({ [size]: true }));
  };

  _disableSize = (size, complete) =>
    this.setState(
      {
        [size]: false,
      },
      complete
    );
}

export default connect(null, { setSeenEntry })(
  withRouter(withBreakpoint(Project, { leading: true }))
);
