import React from 'react'
import { getAsset } from '../helpers/utils'
// import { IMAGE_SIZES } from '../helpers/constants'

const Image = ({ assetId, ...rest }) => {
  const { url, title } = getAsset(assetId)
  // const { url, title, maxWidth, landscape, contentType } = getAsset(assetId)
  // const extension = contentType.split('/')[1]
  // const sizes = IMAGE_SIZES[landscape ? 'widths' : 'heights']
  // const srcSet = sizes
  //   .slice(0, sizes.indexOf(maxWidth) + 1)
  //   .map(size => `${assetId}-${size}.${extension} ${size}w`)
  //   .join(',')
  // const src = `${assetId}-${maxWidth}.${extension}`

  return (
    <img
      srcSet={`${url}?w=320&q=90 320w,${url}?w=580&q=90 580w,${url}?w=768&q=90 768w,${url}?w=1024&q=90 1024w,${url}?w=1280&q=90 1280w,${url}?w=1440&q=90 1440w,${url}?w=1600&q=90 1600w,${url}?w=2000&q=90 2000w,${url}?w=2500&q=90 2500w`}
      src={`${url}?w=1024&q=90`}
      alt={title}
      {...rest}
    />
  )
}

export default Image

//images.ctfassets.net/el3h7kb4yrb8/4VVMK0CUUHP4defm93PgkK/a3dfd6505a07d4cf4ffa052230294fc2/smiley-balloon.jpg?w=400&q=90
//images.ctfassets.net/el3h7kb4yrb8/4VVMK0CUUHP4defm93PgkK/a3dfd6505a07d4cf4ffa052230294fc2/smiley-balloon.jpg?w=400&h=524&q=90 1x,
//images.ctfassets.net/el3h7kb4yrb8/4VVMK0CUUHP4defm93PgkK/a3dfd6505a07d4cf4ffa052230294fc2/smiley-balloon.jpg?w=600&h=786&q=90 1.5x
