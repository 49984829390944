import React, {PureComponent} from 'react'
import bp from 'savnac-breakpoint'

const breakpoint = bp()

const withBreakpoint = (Component, opts = {}) => {
  return class C extends PureComponent {
    _refs = {}
    _callback = null

    componentDidMount() {
      this._callback = Component.prototype._onBreakpointChange.bind(this._refs.comp)
      breakpoint.on('change', this._callback, opts)
    }

    componentWillUnmount() {
      breakpoint.off('change', this._callback, opts)
    }

    render() {
      return <Component {...this.props} ref={node => this._refs.comp = node} />
    }
  }
}

export {
  breakpoint,
  withBreakpoint
}
