import React, { useState } from 'react'
import classNames from 'classnames'
import './PasswordProtected.scss'

export default ({ password, children }) => {
  const [userPass, setUserPass] = useState('')
  const [isInputting, setIsInputting] = useState(true)
  const [hasError, setHasError] = useState(false)

  if (!password || (!isInputting && !hasError)) return children

  const onSubmit = (e) => {
    e.preventDefault()
    setIsInputting(false)
    setHasError(userPass !== password)
  }

  const onInput = (e) => {
    setUserPass(e.target.value)
    setIsInputting(true)
    setHasError(false)
  }

  return (
    <div className={classNames('pass', { 'pass--error': hasError })}>
      <form className="pass__form" onSubmit={onSubmit}>
        <label htmlFor="pass__form__password">Password</label>
        <input
          id="pass__form__password"
          type="password"
          className="pass__form__input"
          value={userPass}
          onChange={onInput}
        />
        <button className="pass__form__submit" type="submit">
          Submit
        </button>
        <p className="pass__form__error type--b1 type--red-1">
          The password is incorrect, please try again.
        </p>
      </form>
    </div>
  )
}
