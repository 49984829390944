import * as actionTypes from './actionTypes'

// Nav
export const setNavState = state => ({
  state,
  type: actionTypes.SET_NAV_STATE,
})

export const setSeenEntry = (seenEntry = true) => ({
  seenEntry,
  type: actionTypes.SET_SEEN_ENTRY,
})

export const setAllowAnalytics = (allowAnalytics = true) => ({
  allowAnalytics,
  type: actionTypes.SET_ALLOW_ANALYTICS,
})
