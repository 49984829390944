import React, { PureComponent, Fragment } from "react";
import { connect, batch } from "react-redux";
import classNames from "classnames";
import ScrolledContainer from "../ScrolledContainer";
import { Grid, Column, Entry, Link, Image } from "../../components";
import { setNavState, setSeenEntry } from "../../actions";
import { projectPath, globalStyles, NAV_STATES } from "../../helpers";
import { getProject } from "../../helpers/utils";
import { HomeMeta } from "../../.config/meta";

import "./styles.scss";
import { smbData } from "../../.config/environment";

const { bodyHorizontalPadding } = globalStyles;
const { PARTIAL, PARTIAL_TO_NORMAL } = NAV_STATES;

class Home extends PureComponent {
  constructor(props) {
    super(props);
    const { homepage } = smbData();
    this.homepageData = homepage;
    this.homepageProjects = this.homepageData.projects.map(getProject);
    this.state = {
      activeArchive: -1,
      activeArchiveMouse: -1,
      activeProject: -1,
      preventScrolledTrigger: !props.seenEntry,
    };

    this._stopArchive = false;
    this._timeoutId = 0;
  }

  componentWillUnmount() {
    window.clearTimeout(this._timeoutId);
  }

  render() {
    const entryComplete =
      [PARTIAL, PARTIAL_TO_NORMAL].indexOf(this.props.navState) === -1;
    return (
      <Fragment>
        <HomeMeta />
        <ScrolledContainer preventTrigger={this.state.preventScrolledTrigger}>
          <div>
            {!this.props.seenEntry && (
              <Entry
                pauseVideo={this.props.navState === PARTIAL_TO_NORMAL}
                onComplete={this._handleEntryComplete}
              />
            )}

            <div className="entry-complete" />

            <main
              className={classNames("home-main", {
                "home-main--revealed": entryComplete,
              })}
            >
              <h2 className="is-visually-hidden">Projects</h2>
              <ul className="projects">
                {this.homepageProjects.map((project, i) => (
                  <li key={i} className="home-project">
                    <Link
                      to={projectPath(project.slug)}
                      color="navy-1"
                      className="home-project__title"
                      forceHover={this.state.activeProject === i}
                      attrs={{
                        onMouseEnter: this._onProjectMouse(i),
                        onMouseLeave: this._onProjectMouse(-1),
                      }}
                    >
                      <h3
                        className="type--a2"
                        dangerouslySetInnerHTML={{ __html: project.title }}
                      ></h3>
                    </Link>
                    <Grid flush={true} gutter={false} responsive={true}>
                      <Column
                        offset={project.featuredImageOffset}
                        size={project.featuredImageColumns}
                      >
                        <Link
                          className={classNames("home-project__img-link", {
                            "is-hovered": this.state.activeProject === i,
                          })}
                          to={projectPath(project.slug)}
                          attrs={{
                            onMouseEnter: this._onProjectMouse(i),
                            onMouseOut: this._onProjectMouse(-1),
                          }}
                        >
                          <Image
                            className="grid__img"
                            assetId={project.featuredImage}
                          />
                          {project.featuredImageHover && (
                            <Image
                              className="home-project__img-link__hover"
                              assetId={project.featuredImageHover}
                            />
                          )}
                        </Link>
                      </Column>
                    </Grid>
                  </li>
                ))}
              </ul>

              <div className="archive">
                <h2
                  style={{ paddingLeft: bodyHorizontalPadding }}
                  className="type--a2 type--blue-1"
                >
                  Archive
                </h2>
                <div className="archive-grid">
                  {this.homepageData.archives.map(this._archiveColumn)}
                </div>
              </div>
            </main>
          </div>
        </ScrolledContainer>
      </Fragment>
    );
  }

  _setIndexState = (prop) => (i) => () => this.setState({ [prop]: i });
  _onArchiveMouse = this._setIndexState("activeArchiveMouse");
  _onProjectMouse = this._setIndexState("activeProject");

  _archiveColumn = (archive, i) => {
    const { activeArchiveMouse, activeArchive } = this.state;
    const attrs = {
      onMouseEnter: this._onArchiveMouse(i),
      onMouseOut: this._onArchiveMouse(-1),
    };
    const text = archive.project ? (
      <Link
        color="blue-1"
        to={projectPath(archive.project)}
        className="type--a6 type--inline-block type--underline"
        attrs={attrs}
      >
        <span
          style={{ pointerEvents: "none" }}
          dangerouslySetInnerHTML={{ __html: archive.text }}
        ></span>
      </Link>
    ) : (
      <span
        className="type--inline-block type--b1"
        dangerouslySetInnerHTML={{ __html: archive.text }}
        {...attrs}
      ></span>
    );

    const activeIndex =
      activeArchiveMouse > -1 ? activeArchiveMouse : activeArchive;

    return (
      <div
        className={classNames("archive-grid__col", {
          "is-active": i === activeIndex,
        })}
        key={i}
      >
        {text}
        {archive.image && (
          <Image assetId={archive.image} className="archive-grid__col__img" />
        )}
      </div>
    );
  };

  _handleEntryComplete = () => {
    batch(() => {
      this.props.setSeenEntry();
      this.props.setNavState(PARTIAL_TO_NORMAL);
    });

    this._timeoutId = setTimeout(() => {
      this.setState({ preventScrolledTrigger: false });
      if (!("ontouchstart" in window)) {
        this._cycleArchive();
      }
    }, 3000); // delay is so the NavBar animation from partial to normal is complete
  };

  _cycleArchive = () => {
    if (this._stopArchive) return;
    const delay = 2500;
    const archives = this.homepageData.archives;

    const nextI = Math.floor(Math.random() * archives.length);

    if (!archives[nextI] || nextI === this.state.activeArchive) {
      return this._cycleArchive();
    }

    this.setState({ activeArchive: nextI });

    this._timeoutId = setTimeout(this._cycleArchive, delay);
  };
}

const mapStateToProps = ({ nav }) => ({
  navState: nav.state,
  seenEntry: nav.seenEntry,
});

export default connect(mapStateToProps, { setNavState, setSeenEntry })(Home);
