import React, { PureComponent } from 'react'
import classNames from 'classnames'

import { Image, Video } from '..'
import { getAsset } from '../../helpers/utils'
import { playBtn } from '../../assets/images'
import './VideoWrapper.scss'

export default class ImgVideo extends PureComponent {
  static defaultProps = {
    style: {},
    onLoad: () => null,
  }

  constructor(props) {
    super(props)

    this.state = {
      isPlaying: false,
      isVideo: this._isVideo()
    }

    this._videoAttrs = props.useAudio
      ? {}
      : {
          loop: true,
          muted: true,
          autoPlay: true,
        }

    this._videoRef = React.createRef()
  }

  componentDidUpdate() {
    this.setState({isVideo: this._isVideo()})
  }

  render() {
    return this.state.isVideo ? (
      <div
        style={this.props.style}
        className={classNames('video-wrapper', { 'video-wrapper--playing': this.state.isPlaying })}
      >
        <Video
          onEnded={() => this.setState({ isPlaying: false })}
          className="grid__img"
          assetId={this.props.assetId}
          posterId={this.props.poster}
          ref={this._videoRef}
          {...this._videoAttrs}
        />
        {this.props.useAudio && (
          <button className="video-wrapper__play" onClick={this._togglePlay}>
            <img src={playBtn} alt="Play Video" />
          </button>
        )}
      </div>
    ) : (
      <Image
        assetId={this.props.assetId}
        style={this.props.style}
        className="grid__img"
        onLoad={this.props.onLoad}
      />
    )
  }

  _isVideo = () => getAsset(this.props.assetId).url.match(/\.mp4/) !== null

  _togglePlay = () => {
    this.setState({ isPlaying: !this.state.isPlaying })
    const method = this.state.isPlaying ? 'pause' : 'play'
    this._videoRef.current[method]()
  }
}
