import React from 'react'
import { Grid, Column, Link } from '.'

const year = new Date().getFullYear()

export default () => (
  <footer className="footer">
    <Grid>
      <Column offset={0} size={7}>
        <span className="type--b1">&copy;</span>
      </Column>
      <Column offset={7} size={11} style={{ textAlign: 'right' }}>
        <Link className="type--b1 type--underline" href="/privacy-policy.pdf" color="blue-1">
          privacy policy
        </Link>
      </Column>
    </Grid>
    <Grid>
      <Column offset={10} size={3}>
        <p className="type--b1">{`2005-${year}`}</p>
      </Column>
    </Grid>
    <Grid>
      <Column offset={0} size={5}>
        <p className="type--b1">all rights</p>
      </Column>
      <Column offset={5} size={2}>
        <p className="type--b1">reserved</p>
      </Column>
      <Column offset={7} size={11}>
        <p className="type--b1 type--right">to supermorebetter and respective clients</p>
      </Column>
    </Grid>
  </footer>
)
