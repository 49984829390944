import React, { PureComponent } from 'react'
import classNames from 'classnames'

import { Column } from '..'
import { fillGaps } from '../../helpers'
import './styles.scss'

class Grid extends PureComponent {
  static defaultProps = {
    flush: false,
    flushOnMobile: false,
    align: 'normal',
    gutter: true,
    style: {},
    className: '',
    responsive: false,
  }

  constructor(props) {
    super(props)

    this._children = this._setChildren(props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this._children = this._setChildren(nextProps)
  }

  render() {
    const {
      align,
      style,
      className,
      gutter,
      flush,
      flushOnMobile,
      responsive,
      innerRef,
      ...rest
    } = this.props

    return (
      <div
        className={classNames(`grid grid--${align}`, className, {
          'grid--gutter': gutter,
          'grid--flush-on-mobile': flushOnMobile,
          'grid--flush': flush,
          'grid--responsive': responsive,
        })}
        style={style}
        ref={innerRef}
        {...rest}
      >
        {this._children}
      </div>
    )
  }

  _setChildren = (props) => {
    const sortedChildren = React.Children.toArray(props.children).sort(
      (a, b) => a.props.offset - b.props.offset
    )
    const extractedProps = sortedChildren.map(({ props: { offset, size } }) => [offset, size])
    const childrenLength = React.Children.count(props.children)

    return fillGaps(extractedProps).map(([offset, size], i) => {
      const ind = extractedProps.findIndex((item) => item[0] === offset)
      return ind < 0 ? (
        <Column offset={offset} size={size} className="column--empty" key={`empty-${i}`} />
      ) : childrenLength === 1 && !Array.isArray(props.children) ? (
        props.children
      ) : (
        sortedChildren[ind]
      )
    })
  }
}

export default Grid
export const GridWithRef = React.forwardRef((props, ref) => <Grid innerRef={ref} {...props} />)
