import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import { NavBar, SiteFooter, Grid, Column, CookieBanner } from "./components";
import { routes, globalStyles, breakpoint } from "./helpers";
import { ClientRender } from "./components/ClientRender";

const times = [];
for (let i = 0; i < 18; i++) times.push(i);

export default class App extends Component {
  componentDidMount() {
    breakpoint.enable();
    setTimeout(() => window.scrollTo(0, 0), 100);
    const touchClass = "ontouchstart" in window ? "touch" : "no-touch";
    const scrollbarClass =
      window.innerWidth > document.documentElement.clientWidth
        ? "has-scrollbar"
        : "no-scrollbar";
    document.body.classList.add(touchClass, scrollbarClass);
  }

  render() {
    return (
      <div
        className="page-wrapper"
        style={{ paddingBottom: globalStyles.bodyHorizontalPadding }}
      >
        <ClientRender>
          <CookieBanner />
          <NavBar />
        </ClientRender>
        <Switch>
          {routes.map((props, i) => (
            <Route {...props} key={i} />
          ))}
        </Switch>
        <SiteFooter />

        <Grid
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            pointerEvents: "none",
            display: "none",
          }}
        >
          {times.map((i) => (
            <Column key={i} offset={i} size={1}>
              <div
                style={{
                  backgroundColor: "#aaa",
                  height: "100vh",
                  opacity: 0.5,
                }}
              ></div>
            </Column>
          ))}
        </Grid>
      </div>
    );
  }
}
