import React from 'react'
import classNames from 'classnames'

import { Grid, Column } from '..'

import "./styles.scss"

const convertFontSize = size =>
  ({
    small: 'type--b1',
    medium: 'type--a35',
    large: 'type--a31',
    'x-large': 'type--a52',
  }[size])

export default ({ copy, size, offset, columns }) => (
  <Grid gutter={false} flush={true} responsive className="copy-grid">
    <Column offset={offset} size={columns}>
      <p className={classNames(convertFontSize(size), "copy-grid__text")} dangerouslySetInnerHTML={{ __html: copy }}></p>
    </Column>
  </Grid>
)
