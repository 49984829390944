//  ==========================================================================
//
//  Infinite Scroll
//
//  ==========================================================================
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import imagesLoaded from 'imagesloaded'

import {checkpoint} from '../helpers'

export default class InfiniteScroll extends Component {
  static propTypes = {
    skip: PropTypes.number,
    paginate: PropTypes.number,
    defaultPagination: PropTypes.number,
    data: PropTypes.array
  }

  static defaultProps = {
    // how many to start paginating after
    skip: 0,
    // amount to paginate by
    paginate: 6,
    defaultPagination: 1,
    data: []
  }

  constructor(props) {
    super(props)

    this.state = this._defaultState(props)

    this._refs = {}
    this._checkpoint = checkpoint()
  }

  componentDidMount() {
    this._checkpoint.init()

    this._checkpoint.addCheckpoint({
      element: this._refs.container,
      trigger: 'bottom',
      offset: 1.1,
      handler: this._loadMore
    })

    imagesLoaded(this._refs.container, this._checkpoint.refresh)
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._defaultState(nextProps), () => {
      if (this._checkpoint) this._checkpoint.refresh()
    })
  }

  componentWillUnmount() {
    this._checkpoint.destroy()
  }

  render() {
    return (
      <div className="paginated" ref={node => this._refs.container = node}>
        {React.cloneElement(this.props.children, {
          data: this.state.paginatedData,
          skip: this.props.skip
        })}
      </div>
    )
  }

  _applyPagination = (currentPagination, props = this.props) => {
    const {paginate, skip, data} = props
    if (paginate <= 0) return data

    return [
      ...data.slice(0, skip),
      ...data.slice(skip, skip + (paginate * currentPagination))
    ]
  }

  _incrementPagination = prevState => ({currentPagination: prevState.currentPagination + 1})

  _loadMore = direction => {
    if (direction !== 'down' || this.state.changing || this._allDataRendered()) return

    this.setState({changing: true})
    this.setState(this._incrementPagination)
    this.setState(({currentPagination}) => ({paginatedData: this._applyPagination(currentPagination)}))

    this._checkpoint.refresh()

    this.setState({changing: false})
  }

  _allDataRendered = () => this.props.data.length === this.state.paginatedData.length

  _defaultState = props => ({
    paginatedData: this._applyPagination(props.defaultPagination, props),
    currentPagination: props.defaultPagination,
    changing: false
  })
}
