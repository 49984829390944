import get from 'lodash.get'
import includes from 'lodash.includes'
import { SET_NAV_STATE, SET_SEEN_ENTRY, SET_ALLOW_ANALYTICS } from '../actions/actionTypes'

import { NAV_STATES, triggerGtagPageview } from '../helpers'
import { localStore } from '../helpers/storageFactory'

const storageKey = 'smb-analytics'

const DEFAULT_STATE = {
  state: NAV_STATES.PARTIAL,
  seenEntry: false,
  pageviews: 0,
  allowAnalytics: JSON.parse(localStore.getItem(storageKey)) || false,
}

const parsePathname = (pathname, { pageviews, seenEntry }) => {
  if (includes(pathname, '/careers')) return NAV_STATES.NORMAL

  switch (pathname) {
    case '/':
      return !pageviews || !seenEntry ? NAV_STATES.PARTIAL : NAV_STATES.NORMAL
    case '/about':
    case '/studies':
    case '/404':
      return NAV_STATES.NORMAL
    default:
      return NAV_STATES.SHOW
  }
}

export default (state = DEFAULT_STATE, action) => {
  const pathname = get(action, 'payload.location.pathname')

  switch (action.type) {
    case SET_NAV_STATE:
      if (!Object.values(NAV_STATES).includes(action.state)) {
        console.error('invalid nav state passed. no change made')
        return state
      }
      return {
        ...state,
        state: action.state,
      }
    case SET_SEEN_ENTRY:
      return {
        ...state,
        seenEntry: action.seenEntry,
      }
    case SET_ALLOW_ANALYTICS:
      localStore.setItem(storageKey, action.allowAnalytics)
      return {
        ...state,
        allowAnalytics: action.allowAnalytics,
      }
    case '@@router/LOCATION_CHANGE':
      if (state.allowAnalytics) triggerGtagPageview()
      return {
        ...state,
        pathname,
        state: parsePathname(pathname, state),
        pageviews: state.pageviews + 1,
      }
    default:
      return {
        ...state,
        pathname: pathname || state.pathname,
      }
  }
}
