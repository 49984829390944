import React, { PureComponent, createRef } from "react";
import classNames from "classnames";

import { HeroVideo, Grid, Column } from "..";
import { withBreakpoint } from "../../helpers";
import { getAsset } from "../../helpers/utils";

import "./styles.scss";
import { smbData, globals } from "../../.config/environment";

class Entry extends PureComponent {
  constructor() {
    super();
    this.state = {
      size: null,
      scrollingComplete: false,
    };

    this._videoWrapperRef = createRef();
    this._videoRef = createRef();
    this._shouldFreeze = true;
    this._observer = null;
    this._timeoutIds = [];
    this._window = globals.getGlobalObj();
    this._homepageData = smbData().homepage;
    this._leadingImage = getAsset(this._homepageData.leadingImage);
    this._showreelPoster = getAsset(this._homepageData.showreelPoster);
  }

  componentDidMount() {
    this._observer = new IntersectionObserver(this._freezeHandler, {
      threshold: [0, 0.99],
    });
    this._observer.observe(this._videoWrapperRef.current);
    this._observer.observe(this._videoRef.current);
  }

  componentDidUpdate() {
    if (this.props.pauseVideo && !this._videoRef.current.paused)
      this._videoRef.current.pause();
  }

  componentWillUnmount() {
    this._timeoutIds.forEach(this._window.clearTimeout);
    this._observer.disconnect();
  }

  render() {
    const isLandscape = this._window.innerWidth >= this._window.innerHeight;

    return (
      <div className="entry">
        <div
          className={classNames("entry__start", {
            "scrolling-complete": this.state.scrollingComplete,
          })}
        ></div>

        {this._leadingImage && (
          <div className="entry__full-section">
            <Grid
              flush={this._toSize(true, true, false)}
              gutter={this._toSize(false, false, true)}
            >
              <Column
                offset={this._toSize(4, 4, 5, 6)}
                size={this._toSize(10, 10, 8, 6)}
              >
                <img
                  src={this._leadingImage.url}
                  alt={this._leadingImage.title}
                />
              </Column>
            </Grid>
          </div>
        )}

        {this._homepageData.leadingCopy && (
          <Grid>
            <Column offset={0} size={18}>
              <p
                className={classNames("type--a0 type--navy-1", {
                  "leading-copy--small": isLandscape,
                  "leading-copy--large": !isLandscape,
                })}
              >
                {this._homepageData.leadingCopy}
              </p>
            </Column>
          </Grid>
        )}

        <div
          className="entry__full-section"
          style={{ marginTop: 50 }}
          ref={this._videoWrapperRef}
        >
          <HeroVideo
            assetId={this._homepageData.showreel}
            poster={this._showreelPoster.url}
            videoRef={this._videoRef}
          />
        </div>
      </div>
    );
  }

  _toSize = (sm, md = sm, lg = md, xl = lg) =>
    ({ sm, md, lg, xl }[this.state.size]);

  _freezeHandler = (entries) => {
    const { intersectionRatio, target } = entries[0];

    if (target.tagName === "VIDEO") {
      this._entryEndHandler(entries);
      return;
    }

    if (intersectionRatio < 0.1) {
      this._shouldFreeze = true;
      this._videoRef.current.pause();
    } else if (intersectionRatio > 0.99 && this._shouldFreeze) {
      this._shouldFreeze = false;
      this._videoRef.current.play();
      document.body.classList.add("no-scroll");
      this._timeoutIds.push(
        setTimeout(() => {
          document.body.classList.remove("no-scroll");
        }, 1500)
      );
    }
  };

  _entryEndHandler = (entries) => {
    const {
      isIntersecting,
      boundingClientRect: { y },
    } = entries[0];
    if (isIntersecting) return;

    if (y < 0) {
      this.setState({ scrollingComplete: true });
      this._timeoutIds.push(setTimeout(this.props.onComplete, 350));
    }
  };

  //  ==========================================================================
  //  Breakpoint functinoality
  //  ==========================================================================
  _onBreakpointChange({ newBreakpoint, previousBreakpoint }) {
    switch (newBreakpoint) {
      case "sm":
      case "md":
      case "lg":
      case "xl":
        this.setState(() => ({ size: null }));
        this.setState(() => ({ size: newBreakpoint }));
        return;
      default:
        console.log("case not set up for: ", typeof newBreakpoint);
        return;
    }
  }
}

export default withBreakpoint(Entry, { leading: true });
