export const globalStyles = {
  bodyHorizontalPadding: 30
}

export const NAV_STATES = {
  PARTIAL: 'PARTIAL',
  PARTIAL_TO_NORMAL: 'PARTIAL_TO_NORMAL',
  NORMAL: 'NORMAL',
  REDUCED: 'REDUCED',
  SHOW: 'SHOW'
}

export const IMAGE_SIZES = {
  widths: [320, 640, 960, 1280, 1600, 1920, 2400],
  heights: [320, 480, 640, 960],
}
