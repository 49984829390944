import React, { PureComponent, Fragment } from "react";
import kebabCase from "lodash.kebabcase";

import { Grid, Column } from "../../components";
import ScrolledContainer from "../ScrolledContainer";
import { careerPath, withBreakpoint } from "../../helpers";
import { getAsset, cloudfrontImage } from "../../helpers/utils";
import { CareerMeta } from "../../.config/meta";

import "./styles.scss";
import { smbData } from "../../.config/environment";

class Career extends PureComponent {
  state = {
    size: null,
  };

  constructor(props) {
    super(props);

    const { jobs: jobsData } = smbData();
    this.slug = props.match.params.name;
    this.job = jobsData.find(
      ({ position }) => kebabCase(position) === this.slug
    );
  }

  render() {
    if (!this.job) {
      this.props.history.replace("/404");
      return null;
    }

    const {
      description,
      greatToHaves,
      offerings,
      position,
      requirements,
      responsibilities,
    } = this.job;

    const heroImage = getAsset(this.job.heroImage);

    const isMobile = this.state.size === "mobile";

    return (
      <ScrolledContainer>
        <CareerMeta
          title={`${position} - supermorebetter`}
          path={careerPath(this.slug)}
        />
        <div className="career">
          <Grid flush={false} className="career__position">
            <Column offset={0} size={7}>
              <h1 className="type--a1 type--lowercase">{position}</h1>
            </Column>
          </Grid>
          {heroImage && (
            <Grid
              flush={!isMobile}
              gutter={!!isMobile}
              className="career__smiley"
            >
              <Column offset={isMobile ? 4 : 8} size={isMobile ? 14 : 10}>
                <img src={heroImage.url} alt={heroImage.title} />
              </Column>
            </Grid>
          )}
          {description && (
            <Grid flush={false} className="career__description">
              <Column offset={isMobile ? 1 : 0} size={15}>
                <Fragment>
                  {description.map((line, i) => (
                    <p className="type--a33" key={i}>
                      {line}
                    </p>
                  ))}
                </Fragment>
              </Column>
            </Grid>
          )}

          {(responsibilities || requirements || greatToHaves) && (
            <Grid flush={false} style={{ marginTop: isMobile ? 30 : 60 }}>
              <Column offset={isMobile ? 1 : 0} size={isMobile ? 17 : 18}>
                <h2 className="type--a2 type--blue-1">you</h2>
              </Column>
            </Grid>
          )}

          {responsibilities && (
            <Fragment>
              <Grid flush={false} style={{ marginTop: isMobile ? 15 : 60 }}>
                <Column offset={isMobile ? 1 : 2} size={isMobile ? 17 : 15}>
                  <p className="type--b1">Ongoing responsibilities include</p>
                </Column>
              </Grid>
              <Grid flush={false} style={{ marginTop: 20 }}>
                <Column offset={isMobile ? 4 : 8} size={isMobile ? 14 : 7}>
                  <ul className="bullet-list type--a34">
                    {responsibilities.map((line, i) => (
                      <li key={i} className="bullet-list__item">
                        {line}
                      </li>
                    ))}
                  </ul>
                </Column>
              </Grid>
            </Fragment>
          )}

          {requirements && (
            <Fragment>
              <Grid flush={false} style={{ marginTop: isMobile ? 35 : 90 }}>
                <Column offset={isMobile ? 9 : 2} size={9}>
                  <p className="type--b1">Requirements</p>
                </Column>
              </Grid>
              <Grid flush={false} style={{ marginTop: 20 }}>
                <Column offset={isMobile ? 2 : 1} size={isMobile ? 12 : 7}>
                  <ul className="bullet-list type--a34">
                    {requirements.map((line, i) => (
                      <li key={i} className="bullet-list__item">
                        {line}
                      </li>
                    ))}
                  </ul>
                </Column>
              </Grid>
            </Fragment>
          )}

          {greatToHaves && (
            <Fragment>
              <Grid flush={false} style={{ marginTop: isMobile ? 20 : 90 }}>
                <Column offset={isMobile ? 1 : 8} size={isMobile ? 17 : 10}>
                  <p className="type--b1">Great to have</p>
                </Column>
              </Grid>
              <Grid flush={false} style={{ marginTop: 20 }}>
                <Column offset={isMobile ? 4 : 10} size={isMobile ? 14 : 8}>
                  <ul className="bullet-list type--a34">
                    {greatToHaves.map((line, i) => (
                      <li key={i} className="bullet-list__item">
                        {line}
                      </li>
                    ))}
                  </ul>
                </Column>
              </Grid>
            </Fragment>
          )}

          {offerings && (
            <Fragment>
              <Grid className="career__offer">
                <Column offset={0} size={18}>
                  <h2 className="type--a2 type--blue-1 career__offer__title">
                    what we offer
                  </h2>
                </Column>
              </Grid>
              <Grid flush={false} style={{ marginTop: isMobile ? 20 : 70 }}>
                <Column offset={isMobile ? 1 : 7} size={8}>
                  <ul className="bullet-list type--a34">
                    {offerings.map((line, i) => (
                      <li key={i} className="bullet-list__item">
                        {line}
                      </li>
                    ))}
                  </ul>
                </Column>
              </Grid>
            </Fragment>
          )}

          <Grid flush={false} style={{ marginTop: isMobile ? 20 : 120 }}>
            <Column offset={isMobile ? 3 : 8} size={isMobile ? 15 : 10}>
              <img
                src={cloudfrontImage("about-collaborate.jpg")}
                alt="desktop computer"
                style={{ width: "100%" }}
              />
            </Column>
          </Grid>

          <Grid
            style={{
              marginTop: isMobile ? 25 : "-20%",
              marginBottom: isMobile ? 30 : "25%",
            }}
          >
            <Column offset={isMobile ? 0 : 3} size={isMobile ? 17 : 15}>
              <Fragment>
                <h2
                  className={`${
                    isMobile ? "type--a2" : "type--a33"
                  } type--blue-1`}
                >
                  contact us at-
                </h2>
                <a
                  className={`link type--a33 type--block career__email ${
                    isMobile ? "link--black" : "link--blue-1"
                  }`}
                  href="#"
                >
                  careers@supermorebetter.com
                </a>
              </Fragment>
            </Column>
          </Grid>
        </div>
      </ScrolledContainer>
    );
  }

  //  ==========================================================================
  //  Breakpoint functinoality
  //  ==========================================================================
  _onBreakpointChange({ newBreakpoint, previousBreakpoint }) {
    switch (newBreakpoint) {
      case "sm":
        this.setState(() => ({ size: null }));
        this.setState(() => ({ size: "mobile" }));
        return;
      case "md":
      case "lg":
      case "xl":
        this.setState(() => ({ size: null }));
        this.setState(() => ({ size: "tablet" }));
        return;
      default:
        console.log("case not set up for:", typeof newBreakpoint);
        return;
    }
  }
}

export default withBreakpoint(Career, { leading: true });
