import {
  Home,
  About,
  Project,
  Studies,
  Career,
  NotFound,
} from '../containers'

export const paths = {
  PROJECTS: '/',
  STUDIES: '/studies',
  ABOUT: '/about'
}

export const routes = [
  {
    exact: true,
    path: paths.PROJECTS,
    component: Home
  }, {
    exact: true,
    path: paths.ABOUT,
    component: About
  }, {
    exact: true,
    path: paths.STUDIES,
    component: Studies
  }, {
    path: `/projects/:name`,
    component: Project
  }, {
    path: `/careers/:name`,
    component: Career
  }, {
    component: NotFound
  }
]

export const isHomepage = path => path === '/'

export const isMinimalNavPage = path => /\/projects\/.+/.test(path)

export const projectPath = slug => `/projects/${slug}`

export const careerPath = slug => `/careers/${slug}`
