import React, { PureComponent } from 'react'
import SVGInline from 'react-svg-inline'
import { TweenLite, TimelineMax, Power0, Power1, Power2, Power4 } from 'gsap'

import { smbLogoRaw } from '../../assets/images'

import './Logo.scss'

const sum = (a, b) => a + b
const sumArr = (arr) => arr.reduce(sum)
const sliceArr = (arr) => (start, end) => arr.slice(start, end)

const stepDurations = [0.3, 0.09, 0.15, 0.2, 0.12]
const stepSliceSum = (start, end) => sumArr(sliceArr(stepDurations)(start, end))
const DURATION = 2
const timesDur = (n) => n * DURATION
const WINK_START = timesDur(sumArr(stepDurations) * 1.3)
const WINK_DURATION = 0.06

export default class Logo extends PureComponent {
  _wrapper = null
  _svgGt = null
  _svgPlV = null
  _svgPlH = null
  _svgCircle = null

  componentDidMount() {
    this._svgGt = this._wrapper.querySelector('.smb-svg__gt')
    this._svgGt1 = this._wrapper.querySelector('.smb-svg__gt-1')
    this._svgGt2 = this._wrapper.querySelector('.smb-svg__gt-2')
    this._svgPl = this._wrapper.querySelector('.smb-svg__pl')
    this._svgCircle = this._wrapper.querySelector('.smb-svg__circle')
    this._svgSymbols = this._wrapper.querySelector('.smb-svg__symbols')
  }

  render() {
    return (
      <div
        className="logo js-logo"
        ref={(node) => (this._wrapper = node)}
        onMouseEnter={this._onMouseEnter}
      >
        <SVGInline svg={smbLogoRaw} />
      </div>
    )
  }

  _onMouseEnter = () => {
    if (this._running) return
    this._running = true
    this._runAnimation()
  }

  _runAnimation = () => {
    const tLine = new TimelineMax({
      repeat: 1,
      yoyo: true,
      onComplete: () => (this._running = false),
    })

    const len = 2 * Math.PI * (this._svgCircle.getBoundingClientRect().width / 2)
    const calcDash = (percentage) => len + len * (1 - percentage)

    // animates the circle at various lengths and offsets around the edge
    // set needs to happen so tween knows how to handle rotation origin correctly across all browsers
    // TweenLite.set(this._svgCircle, {strokeDasharray: len, strokeDashoffset: calcDash(1)})
    TweenLite.set(this._svgCircle, {
      x: 0,
      y: 21,
      strokeDasharray: len,
      strokeDashoffset: calcDash(1),
    })
    tLine
      .fromTo(
        this._svgCircle,
        timesDur(stepDurations[0]),
        { rotation: -90, strokeDashoffset: calcDash(1) },
        {
          rotation: 0,
          strokeDashoffset: calcDash(1),
          ease: Power2.easeIn,
          transformOrigin: 'center center',
        }
      )
      .to(this._svgCircle, timesDur(stepDurations[1]), {
        rotation: 90,
        strokeDashoffset: calcDash(1),
        ease: Power0.easeNone,
      })
      .to(this._svgCircle, timesDur(stepDurations[2]), {
        rotation: 170,
        strokeDashoffset: calcDash(0.95),
        ease: Power1.easeOut,
      })
      .to(this._svgCircle, timesDur(stepDurations[3]), {
        rotation: 145,
        strokeDashoffset: calcDash(0.9),
        ease: Power1.easeIn,
      })
      .to(this._svgCircle, timesDur(stepDurations[4]), {
        rotation: 100,
        strokeDashoffset: calcDash(0.7),
        ease: Power2.easeOut,
      })
      // add a little time to the end of the timeline before the yoyo returns
      .to({}, 1, {})

    // not sure why 2 is a good number here?
    tLine.to(this._svgCircle, 2, { duration: 0, opacity: 1 }, 0)

    // rotates the group of symbols (greater-than and plus)
    tLine
      .fromTo(
        this._svgSymbols,
        timesDur(stepSliceSum(0, 2)),
        { rotation: 0 },
        { rotation: 270, ease: Power2.easeIn, transformOrigin: 'center center' },
        0
      )
      .to(
        this._svgSymbols,
        timesDur(stepDurations[2]),
        { rotation: 390, ease: Power1.easeOut, transformOrigin: 'center center' },
        timesDur(stepSliceSum(0, 2))
      )
      .to(
        this._svgSymbols,
        timesDur(stepSliceSum(3, 5)),
        { rotation: 335, ease: Power2.easeInOut, transformOrigin: 'center center' },
        timesDur(stepSliceSum(0, 3))
      )

    // handles shrinking and translating the greater-than and plus symbols to the left and right
    tLine.to(
      this._svgGt,
      timesDur(sumArr(stepDurations) - 0.01),
      { scale: 0.45, x: -5, ease: Power4.easeInOut },
      0
    )
    tLine.to(
      this._svgPl,
      timesDur(sumArr(stepDurations) - 0.01),
      { scale: 0.45, x: 8, ease: Power4.easeInOut },
      0
    )

    TweenLite.to(this._svgGt1, WINK_DURATION, {
      rotation: -27,
      ease: Power0.easeNone,
      delay: WINK_START,
      transformOrigin: 'right bottom',
    })
    TweenLite.to(this._svgGt2, WINK_DURATION, {
      rotation: 26,
      ease: Power0.easeNone,
      delay: WINK_START,
      transformOrigin: 'right top',
    })
    TweenLite.to([this._svgGt1, this._svgGt2], WINK_DURATION, {
      rotation: 0,
      ease: Power0.easeNone,
      delay: WINK_START + WINK_DURATION * 2,
    })
  }
}
