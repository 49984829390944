import React, { PureComponent } from 'react'

import { Video } from '..'
import { muteIcon, volumeIcon } from '../../assets/images'

import './styles.scss'

export default class extends PureComponent {
  state = {
    muted: true,
  }

  render() {
    const { muted } = this.state
    const { videoRef, ...rest } = this.props
    return (
      <div className="hero-video">
        <div className="hero-video__video-wrapper">
          <Video
            {...rest}
            ref={videoRef}
            className="hero-video__video-wrapper__video"
            muted={muted}
          ></Video>
          <button
            className="hero-video__video-wrapper__mute"
            onClick={() => this.setState((state) => ({ muted: !state.muted }))}
          >
            <img
              className="hero-video__video-wrapper__mute__icon"
              src={muted ? muteIcon : volumeIcon}
              alt={muted ? 'Enable audio' : 'Disable audio'}
            />
          </button>
        </div>
      </div>
    )
  }
}
