import {Component} from 'react'
import {connect} from 'react-redux'
import throttle from 'lodash.throttle'

import {setNavState} from '../actions'
import {NAV_STATES} from '../helpers'

const {REDUCED, NORMAL} = NAV_STATES

class ScrolledContainer extends Component {
  constructor(props) {
    super(props)
    this._onScroll = throttle(this._onScroll, 250)
  }

  componentDidMount() {
    this._trigger = window.innerHeight / 2
    window.addEventListener('scroll', this._onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._onScroll)
  }

  render() {
    return this.props.children
  }

  _onScroll = () => {
    if (this.props.preventTrigger || this._trigger < 0) return

    if (window.pageYOffset > this._trigger) {
      if (this.props.navState !== REDUCED) this.props.setNavState(REDUCED)
    } else {
      if (this.props.navState !== NORMAL) this.props.setNavState(NORMAL)
    }
  }
}

const mapStateToProps = ({nav}) => ({navState: nav.state})

export default connect(mapStateToProps, {setNavState})(ScrolledContainer)
