import React from 'react'
import classNames from 'classnames'

const Column = ({ columnStyle, style, className, offset, size, children }) => {
  const atEndOfRow = size + offset >= 18
  return (
    <div
      className={classNames('column', className, {
        [`column--${size}`]: !atEndOfRow,
        [`column--${size}--end`]: atEndOfRow,
      })}
      style={{ ...columnStyle, ...style }}
    >
      {children && React.cloneElement(children)}
    </div>
  )
}

Column.defaultProps = {
  size: 1,
  offset: 0,
  style: {},
  className: '',
}

export default Column
