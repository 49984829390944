class Globals {
  constructor() {
    this.globals = undefined;
  }

  getGlobalObj() {
    if (this.globals) return this.globals;
    try {
      if (window) this.globals = window;
    } catch (_) {
      this.globals = global;
    }
    return this.globals;
  }
}

export const globals = new Globals();

export const smbData = () => {
  return globals.getGlobalObj().SMB_DATA;
};
