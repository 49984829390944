import React, { PureComponent } from 'react'
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import map from 'lodash.map'

import { Link } from '..'
import { paths, NAV_STATES } from '../../helpers'
import './styles.scss'

const { REDUCED, NORMAL, PARTIAL, PARTIAL_TO_NORMAL, SHOW } = NAV_STATES

class Nav extends PureComponent {
  static defaultProps = {}
  constructor(props) {
    super(props)

    this.state = {
      navIsOpen: props.navState !== REDUCED,
    }
    this._timeout = 0
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.navState !== nextProps.navState) {
      clearTimeout(this._timeout)
      const navIsOpen = [PARTIAL, PARTIAL_TO_NORMAL, NORMAL].includes(nextProps.navState)
      const toHomeFirstTime = nextProps.navState === PARTIAL
      this._timeout = setTimeout(
        () => {
          this.setState({ navIsOpen })
        },
        navIsOpen || toHomeFirstTime ? 0 : 500
      )
    }
  }

  render() {
    return (
      <div
        className={classNames(`nav-wrapper nav-wrapper--${this.props.navState.toLowerCase()}`, {
          'is-open': this.state.navIsOpen,
        })}
      >
        <button className="nav-burger" onClick={this._toggleNav}>
          <span className="nav-burger__lines"></span>
          <span className="is-visually-hidden">toggle nav visibility</span>
        </button>
        <nav className="nav">
          <ul className="nav__list">
            {map(paths, (path, name) => (
              <li className="nav__list__item" key={name}>
                <Link className="type--a4 type--lowercase" color="navy-1" to={path}>
                  {name}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    )
  }

  _toggleNav = () => {
    if (this.props.navState === SHOW) {
      if (this.props.pageviews === 1) {
        this.props.history.replace(paths.PROJECTS)
      } else {
        this.props.history.goBack()
      }
    } else {
      this.setState({ navIsOpen: !this.state.navIsOpen })
    }
  }
}

const mapStateToProps = ({ nav }) => ({ navState: nav.state, pageviews: nav.pageviews })

export default withRouter(connect(mapStateToProps)(Nav))
