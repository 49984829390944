import React, { PureComponent } from 'react'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

import { hasPresence } from '../../helpers'

import './Link.scss'

export default class Link extends PureComponent {
  static defaultProps = {
    to: '',
    color: 'black',
    href: '',
    target: '_blank',
    className: '',
    forceHover: false,
    attrs: {},
  }

  render() {
    const { to, children, href, target, attrs, color, className, forceHover } = this.props
    const cl = classNames(`link link--${color}`, className, { 'is-hovered': forceHover })

    return hasPresence(to) ? (
      <NavLink to={to} className={cl} activeClassName="active" exact {...attrs}>
        {children}
      </NavLink>
    ) : (
      <a href={href} target={target} className={cl} {...attrs} rel="noopener noreferrer">
        {children}
      </a>
    )
  }
}
